@import "../../styles/colors.scss";

.faded_hr {
  margin: 20px 0px;
  border: 0px;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    $skoipy-light-grey,
    rgba(0, 0, 0, 0)
  );
}
