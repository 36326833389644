@import "../../styles/colors.scss";

.selected {
  border-bottom: 3px solid $skoipy-dark-purple;
}

.wrapper {
  display: flex;
  margin-bottom: 25px;
}

.tab {
  margin-right: 10px;
  padding: 5px;
  font-weight: bold;
  color: $skoipy-dark-grey;
  &.selected {
    color: black;
  }
}
