@import "../../styles/colors.scss";

.search_wrapper {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .search_result {
    align-items: center;
    display: flex;

    img {
      width: 80px;
    }

    .sub_header {
      color: $skoipy-light-grey;
    }
  }

  .search_bar {
    position: sticky;
    top: 0px;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .search_results {
    display: flex;
    flex-direction: column;
    height: 500px;
  }
}
