@import "./styles/colors.scss";

.content {
  padding: 0px 0px;
}

.Toastify__toast--success {
  background-color: $skoipy-green;
}

.Toastify__toast--error {
  background-color: $skoipy-red;
}
