@import "../../styles/colors.scss";

.landing {
  color: white;
  display: flex;
  align-items: center;
  height: 100vh;
  background: url(Assets/telescope-flipped.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
  background-size: cover;

  .logo {
    position: absolute;
    top: 45px;
    left: 10%;
    font-size: 28px;
    font-weight: bold;
  }

  .landing-content {
    width: 33%;
    margin-left: 10%;

    h1 {
      font-size: 64px;
      margin-top: 0;
      margin-bottom: 0;
    }

    h2 {
      font-size: 20px;
      margin: 40px 0px;
    }
  }
}

@media only screen and (max-width: 1387px) {
  .landing {
    text-align: center;
    justify-content: center;
    background: url(Assets/starry-background.png);
    background-position-x: center;

    .landing-content {
      width: 100%;
      padding: 20px;
      margin: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;

      h1 {
        font-size: 50px;
        margin-top: 0;
        margin-bottom: 0;
      }

      h2 {
        font-size: 20px;
        margin: 40px 0px;
      }
    }
  }
}
