.wrapper {
  padding: 40px;
}

.scheduling_section {
  display: flex;
  margin: 30px 0px;

  :first-child {
    margin-right: 10px;
  }
}

.selection_section {
  display: flex;
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}

.control_buttons {
  display: flex;

  * {
    margin-right: 10px;
  }
}

.check_box {
  display: flex;
  margin-bottom: 10px;
  label {
    margin-right: 10px;
  }
}

.top_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.delete_generator {
  margin-top: 20px;
}
