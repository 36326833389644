@import "../../styles/colors.scss";

.login-button {
  background-color: $skoipy-light-blue;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  width: fit-content;
}
