@import "../../styles/colors.scss";

header {
  align-items: center;
  background: linear-gradient(
    90deg,
    $skoipy-dark-purple 60.26%,
    $skoipy-warm-purple 100%
  );
  color: white;
  display: flex;
  height: 10vh;
  justify-content: space-between;
  padding: 0px 25px;
}

.header-menu {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;

  a {
    color: white;
  }
}

.title {
  font-size: 32px;
  font-weight: bold;
}
