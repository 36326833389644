body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 56px;
}

h2 {
  font-size: 32px;
  margin: 0;
}
