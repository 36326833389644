@import "../../styles/colors.scss";

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .artists {
    border-right: 1px solid $skoipy-light-grey;
    padding-right: 10px;
    width: 100%;
  }

  .tracks {
    border-left: 1px solid $skoipy-light-grey;
    padding-left: 10px;
    width: 100%;
  }
}
