@import "../../styles/colors.scss";

.button-wrapper {
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  width: fit-content;
  border: none;
  color: white;
  border-radius: 5px;

  &.primary-theme {
    background-color: $skoipy-light-blue;

    &:hover {
      background-color: lighten($skoipy-light-blue, 10);
    }
  }

  &.secondary-theme {
    background-color: white;
    border: 1px solid $skoipy-light-blue;
    color: $skoipy-light-blue;

    &:hover {
      background-color: darken(white, 5);
    }
  }

  &.blue-theme {
    background-color: $skoipy-light-blue;

    &:hover {
      background-color: lighten($skoipy-light-blue, 10);
    }
  }

  &.green-theme {
    background-color: $skoipy-green;

    &:hover {
      background-color: lighten($skoipy-green, 10);
    }
  }

  &.red-theme {
    background-color: $skoipy-red;

    &:hover {
      background-color: lighten($skoipy-red, 10);
    }
  }
}
