@import "../../styles/colors.scss";

.wrapper {
  padding: 0px 80px;
}

@media only screen and (max-width: 750px) {
  .wrapper {
    padding: 0px 5px;
  }

  .greeting {
    margin-bottom: 50px;
  }

  .add_generator {
    display: flex;
    justify-content: center;
  }
}

.greeting {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.generator_cards_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

hr {
  margin: 20px 0px;
  border: 0px;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    $skoipy-light-grey,
    rgba(0, 0, 0, 0)
  );
}
